import React, { useContext } from 'react';
import HmiContext from '../../context/HmiContext';
import FormatMessage from '../../messages/FormatMessage';
import AnalyticsContext from '../../context/AnalyticsContext';
import { AnalyticsClickEvents } from '../../utils/AnalyticsEvents';
import HmiPermissionsZApp from './HmiPermissionsZApp';
import { isOwnersApp } from './helpers';
import HmiPermissionsDefaultApp from './HmiPermissionsDefaultApp';

const HmiPermissions: React.FC = () => {
  const hmiContext = useContext(HmiContext);
  const onClickEvent = useContext(AnalyticsContext)?.onClickEvent;
  const { parentApp, onApproved, onRejected } = hmiContext.hmi;

  if (!parentApp) {
    return null;
  }

  const onAgreeClick = () => {
    onClickEvent?.(AnalyticsClickEvents.hmiAgreeButton);
    onApproved();
  };

  const onCancelClick = () => {
    onClickEvent?.(AnalyticsClickEvents.hmiCancelButton);
    onRejected();
  };

  return (
    <div
      className={`ds-hmi-permissions ${
        !isOwnersApp(parentApp) ? 'extra-margin' : ''
      }`}
    >
      <div
        className={`ds-hmi-permissions-wrapper ${
          !isOwnersApp(parentApp) ? 'extra-padded' : ''
        }`}
      >
        {isOwnersApp(parentApp) ? (
          <HmiPermissionsZApp applicationName={parentApp.info.name} />
        ) : (
          <HmiPermissionsDefaultApp />
        )}

        <button
          className={'ds-hmi-btn ds-hmi-btn-primary'}
          onClick={() => onAgreeClick()}
        >
          <FormatMessage id={'hmi.auth.permissions.agreeBtn'} />
        </button>

        <button
          className={'ds-hmi-btn ds-hmi-btn-secondary'}
          onClick={() => onCancelClick()}
        >
          <FormatMessage id={'hmi.actions.cancel'} />
        </button>

        <div className={'ds-hmi-id'}>
          HMI ID: {parentApp.id + '-' + parentApp.info.version}
        </div>
      </div>
    </div>
  );
};

export default HmiPermissions;
