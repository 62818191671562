import React, { useEffect, useState } from 'react';
import MessagesContext from './MessagesContext';
import enMsgs from '../translations/en.json';
import ptMsgs from '../translations/pt.json';

type Props = {
  language?: string;
};

const MessagesProvider: React.FC<Props> = ({ language, children }) => {
  const [messages, setMessages] = useState<{ [index: string]: string }>({});

  useEffect(() => {
    language === 'pt' ? setMessages(ptMsgs) : setMessages(enMsgs);
  }, [language]);

  return (
    <MessagesContext.Provider
      value={{
        messages: messages,
        reset: () => {
          setMessages({});
        },
        init: () => {},
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesProvider;
