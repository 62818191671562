import React from 'react';
import { useEffect, useState } from 'react';
import { RatingBackgroundSvg } from '../Svg/RatingBackgroundSvg';

type Props = {
  points: number;
  children: React.ReactNode;
};

type OffsetType = { offset0: string; offset1: string };

const backgroundColors: Record<string, OffsetType> = {
  green: { offset0: '#a8c62b', offset1: '#6f9b2e' },
  orange: { offset0: '#feae37', offset1: '#f9872d' },
  orangeDark: { offset0: '#f9872d', offset1: '#ef5d3b' },
  red: { offset0: '#ef5d3b', offset1: '#e50d42' },
};

export const RatingBackground: React.FC<Props> = ({ points, children }) => {
  const [offsetColors, setOffsetColors] = useState<OffsetType | null>(null);

  useEffect(() => {
    const calculateOffsetColors = () => {
      if (points < 6) {
        setOffsetColors(backgroundColors['red']);
      } else if (points < 8) {
        setOffsetColors(backgroundColors['orangeDark']);
      } else if (points <= 11) {
        setOffsetColors(backgroundColors['orange']);
      } else if (points > 11) {
        setOffsetColors(backgroundColors['green']);
      }
    };

    calculateOffsetColors();
  }, [points]);

  return (
    <div className={'rating-background'}>
      <div className={'rating-background-content'}>
        <RatingBackgroundSvg
          offset0={offsetColors?.offset0}
          offset1={offsetColors?.offset1}
        />
      </div>
      {children}
    </div>
  );
};
