import React from 'react';

type Props = {
  offset0?: string;
  offset1?: string;
};

export const RatingBackgroundSvg: React.FC<Props> = ({ offset0, offset1 }) => {
  return (
    <svg
      key={`ds-rating-background-${Math.random()}`}
      width="88"
      height="29.721"
      viewBox="0 0 88 29.721"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          id="a-1"
          x1="0.5"
          y1="0.704"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor={offset0 || '#9a9a9a'} />
          <stop offset="1" stopColor={offset1 || '#9a9a9a'} />
        </linearGradient>
      </defs>
      <path
        className="a-1"
        d="M0,1.333,44-12.5,88,1.333V17.221H0Z"
        transform="translate(0 12.5)"
      />
    </svg>
  );
};
