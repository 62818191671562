import React, { useContext } from 'react';
import HmiContext from '../../context/HmiContext';
import ExpansionPanel from '../ExpansionPanel/ExpansionPanel';
import FormatMessage from '../../messages/FormatMessage';
import AnalyticsContext from '../../context/AnalyticsContext';
import { AnalyticsClickEvents } from '../../utils/AnalyticsEvents';

const HmiDuration: React.FC = () => {
  const hmiContext = useContext(HmiContext);
  const onClickEvent = useContext(AnalyticsContext)?.onClickEvent;
  const { parentApp, dependencyContracts } = hmiContext.hmi;

  if (!parentApp) {
    return null;
  }

  return (
    <>
      <ExpansionPanel
        titleId={'hmi.auth.permissions.durationWhen'}
        onClick={() => onClickEvent?.(AnalyticsClickEvents.hmiWhenSection)}
      >
        <div>
          {dependencyContracts && dependencyContracts.length > 0 ? (
            <FormatMessage
              id={'hmi.auth.permissions.durationUntilContractTermination'}
              values={{ appName: parentApp.info.name }}
            />
          ) : (
            <FormatMessage
              id={'hmi.auth.permissions.durationTillDisabled'}
              values={{ appName: parentApp.info.name }}
            />
          )}
        </div>
      </ExpansionPanel>
      <hr />
    </>
  );
};

export default HmiDuration;
