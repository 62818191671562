import * as React from 'react';

type ContextProps = {
  messages: {
    [index: string]: string;
  };
  reset: () => void;
  init: (language: string) => void;
};

const MessagesContext = React.createContext<ContextProps>({
  messages: {},
  reset: () => {},
  init: () => {},
});

export default MessagesContext;
