import React, { useContext } from 'react';
import HmiContext from '../../context/HmiContext';
import ExpansionPanel from '../ExpansionPanel/ExpansionPanel';
import FormatMessage from '../../messages/FormatMessage';
import { AnalyticsClickEvents } from '../../utils/AnalyticsEvents';
import AnalyticsContext from '../../context/AnalyticsContext';

const HmiConnection: React.FC = () => {
  const hmiContext = useContext(HmiContext);
  const onClickEvent = useContext(AnalyticsContext)?.onClickEvent;
  const { parentApp, dependencyApps } = hmiContext.hmi;

  if (!parentApp) return null;

  // TODO Improve if statement by reversing the logic.
  if (
    parentApp.dependencies &&
    parentApp.dependencies.plugs.length > 0 &&
    dependencyApps &&
    dependencyApps?.length > 0
  ) {
    return (
      <>
        <ExpansionPanel
          titleId={'hmi.auth.permissions.connectionWhere'}
          onClick={() => onClickEvent?.(AnalyticsClickEvents.hmiWhereSection)}
        >
          <div>
            <FormatMessage
              id={'hmi.auth.permissions.connectionDescription'}
              values={{ name: parentApp.info.name }}
            />
            <ul className={'instruction-list'}>
              {dependencyApps.map((plug, index) => {
                return (
                  <li className={'list-item'} key={plug.id + index}>
                    {plug.info.name}
                  </li>
                );
              })}
            </ul>
          </div>
        </ExpansionPanel>
        <hr />
      </>
    );
  } else {
    return null;
  }
};

export default HmiConnection;
