import React, { useState, useRef } from 'react';
import './ExpansionPanel.scss';
import Chevron from './Chevron';
import FormatMessage from '../../messages/FormatMessage';

type Props = {
  titleId: string;
  onClick: () => void;
};

const ExpansionPanel: React.FC<Props> = ({ titleId, onClick, children }) => {
  const [firstTimeExpanded, setFirstTimeExpanded] = useState(true);
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('ds-accordion__icon');

  const content = useRef<HTMLObjectElement>(null);

  function toggleAccordion() {
    if (firstTimeExpanded) {
      onClick();
      setFirstTimeExpanded(false);
    }
    setActiveState(setActive === '' ? 'ds-active' : '');
    setHeightState(
      setActive === 'ds-active'
        ? '0px'
        : `${content?.current?.scrollHeight || 0}px`
    );
    setRotateState(
      setActive === 'ds-active'
        ? 'ds-accordion__icon'
        : 'ds-accordion__icon rotate'
    );
  }

  return (
    <div className="ds-accordion__section">
      <button className={`ds-accordion ${setActive}`} onClick={toggleAccordion}>
        <span className="ds-accordion__title">
          <FormatMessage id={titleId} asHtml={true} />
        </span>
        <Chevron className={`${setRotate}`} width={10} fill={'#9B9B9D'} />
      </button>
      <div
        ref={content}
        style={{ height: `${setHeight}` }}
        className="ds-accordion__content outer"
      >
        {children}
      </div>
    </div>
  );
};

export default ExpansionPanel;
