import React from 'react';

type Props = {
  points: number;
};

const ClippedPaths: Record<number, string> = {
  10: 'M3556.145,4461.159H3520.8l3.7,17.507h26.167l15.5-4.333Z',
  20: 'M3558.286,4457.623l-32.667-13.583-6.957,10.46,5.833,24.167h26.167l15.5-4.333Z',
  30: 'M3554.917,4449.236l-22.186-22.186-14.068,27.45,5.833,24.167h26.167l15.5-4.333Z',
  40: 'M3562.8,4451.23l-13.258-32.112-17.625,8.011-13.258,27.371,5.833,24.167h26.167l15.5-4.333Z',
  50: 'M3566.163,4447.353v-33.625L3539,4416.5l-20.333,38,5.833,24.167h26.167l15.5-4.333Z',
  60: 'M3583.011,4455.164l-9.892-8.117,12.562-30.547-11.184-7.167L3539,4416.5l-20.333,38,5.833,24.167h26.167l15.5-4.333Z',
  70: 'M3579.052,4449.689l17.688-17.719,8.424-5.471-30.667-17.167L3539,4416.5l-20.333,38,5.833,24.167h26.167l15.5-4.333Z',
  80: 'M3583.011,4455.164l24.25-10.125-2.1-18.539-30.667-17.167L3539,4416.5l-20.333,38,5.833,24.167h26.167l15.5-4.333Z',
  90: 'M3583,4462h27.667l-5.5-35.5-30.667-17.167L3539,4416.5l-20.333,38,5.833,24.167h26.167l15.5-4.333Z',
};

export const ProgressRing: React.FC<Props> = ({ points }) => {
  const getClippedPath = () => {
    if (points <= 3) {
      return ClippedPaths[10];
    } else if (points <= 5) {
      return ClippedPaths[20];
    } else if (points <= 7) {
      return ClippedPaths[30];
    } else if (points === 8) {
      return ClippedPaths[40];
    } else if (points === 9) {
      return ClippedPaths[50];
    } else if (points === 10) {
      return ClippedPaths[60];
    } else if (points <= 13) {
      return ClippedPaths[70];
    } else if (points <= 16) {
      return ClippedPaths[80];
    } else if (points <= 19) {
      return ClippedPaths[90];
    } else {
      return ClippedPaths[100];
    }
  };

  return (
    <div className={'progress-ring'}>
      <svg
        key={`ds-rating-progress-ring-${Math.random()}`}
        width="110"
        height="110"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        viewBox="0 0 110 110"
      >
        <defs>
          <filter
            id="a"
            x="0"
            y="0"
            width="110"
            height="110"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="5" result="b" />
            <feFlood floodOpacity="0.2" />
            <feComposite operator="in" in2="b" />
            <feComposite in="SourceGraphic" />
          </filter>
          {points < 20 && (
            <clipPath id="c">
              <path className="a" d={getClippedPath()} />
            </clipPath>
          )}

          <linearGradient
            id="d"
            x1="0.091"
            y1="0.47"
            x2="0.755"
            y2="0.468"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#e50d42" />
            <stop offset="0.315" stopColor="#f9872d" />
            <stop offset="0.677" stopColor="#feae37" />
            <stop offset="1" stopColor="#a8c62b" />
          </linearGradient>
          <linearGradient
            id="e"
            x1="0.588"
            y1="0.5"
            x2="0.856"
            y2="0.823"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#6f9b2e" stopOpacity="0" />
            <stop offset="1" stopColor="#6f9b2e" />
          </linearGradient>
          <linearGradient
            id="f"
            x1="0.336"
            y1="0.061"
            x2="0.423"
            y2="0.294"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#feae37" />
            <stop offset="1" stopColor="#feae37" stopOpacity="0" />
          </linearGradient>
        </defs>
        <g transform="translate(-264 -3258)">
          <g className="g" transform="matrix(1, 0, 0, 1, 264, 3258)">
            <path
              className="b"
              d="M40,80A40.011,40.011,0,0,1,24.43,3.143a40.01,40.01,0,0,1,31.14,73.713A39.749,39.749,0,0,1,40,80Zm0-59.088a19.029,19.029,0,1,0,7.43,1.5A18.968,18.968,0,0,0,40,20.912Z"
              transform="translate(15 13)"
            />
          </g>
          <g className="c" transform="translate(-3247 -1150)">
            <path
              className="d"
              d="M40,80A40.011,40.011,0,0,1,24.43,3.143a40.01,40.01,0,0,1,31.14,73.713A39.749,39.749,0,0,1,40,80Zm0-59.088a19.029,19.029,0,1,0,7.43,1.5A18.968,18.968,0,0,0,40,20.912Z"
              transform="translate(3526 4421)"
            />
            <path
              className="e"
              d="M40,80A40.011,40.011,0,0,1,24.43,3.143a40.01,40.01,0,0,1,31.14,73.713A39.749,39.749,0,0,1,40,80Zm0-59.088a19.029,19.029,0,1,0,7.43,1.5A18.968,18.968,0,0,0,40,20.912Z"
              transform="translate(3526 4421)"
            />
            <path
              className="f"
              d="M40,80A40.011,40.011,0,0,1,24.43,3.143a40.01,40.01,0,0,1,31.14,73.713A39.749,39.749,0,0,1,40,80Zm0-59.088a19.029,19.029,0,1,0,7.43,1.5A18.968,18.968,0,0,0,40,20.912Z"
              transform="translate(3526 4421)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
