import React, { useContext } from 'react';
import './AgreementsModal.scss';
import { hmiConfig } from '../../hmi.config';
import FormatMessage from '../../messages/FormatMessage';
import MessagesProvider from '../../context/MessagesProvider';
import AnalyticsContext from '../../context/AnalyticsContext';
import { AnalyticsClickEvents } from '../../utils/AnalyticsEvents';
import { AnalyticsEvent } from '../../interfaces/analytics-event.interface';
import Modal from '../Modal/Modal';

type Props = {
  language: string;
  open: boolean;
  onClose: () => void;
};

export const AgreementsModal: React.FC<Props> = ({
  language,
  open,
  onClose,
}) => {
  const onClickEvent = useContext(AnalyticsContext)?.onClickEvent;

  const getLocalisedUrl = (url: string): string => {
    return url.replace('$lang', language || 'en');
  };

  const onModalClose = (event: AnalyticsEvent) => {
    onClickEvent?.(event);
    onClose();
  };

  return (
    <>
      <MessagesProvider language={language}>
        {open && (
          <Modal
            open={open}
            titleMessageId={'hmi.auth.modal.agreements'}
            onClose={onModalClose}
          >
            <div className={'ds-agreements-modal'}>
              <h3>
                <FormatMessage id={'hmi.auth.modal.title'} />
              </h3>
              <p>
                <a
                  href={getLocalisedUrl(hmiConfig.links.ownersAgreement)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    onClickEvent?.(AnalyticsClickEvents.pdaOwnerAgreementLink)
                  }
                >
                  <FormatMessage id={'hmi.auth.modal.termsOfService'} />
                </a>
              </p>
              <p>
                <a
                  href={getLocalisedUrl(hmiConfig.links.privacyPolicy)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    onClickEvent?.(
                      AnalyticsClickEvents.dsPrivacyPolicyAgreementLink
                    )
                  }
                >
                  <FormatMessage id={'hmi.auth.modal.privacyPolicy'} />
                </a>
              </p>
            </div>
          </Modal>
        )}
      </MessagesProvider>
    </>
  );
};
