import React from 'react';
import FormatMessage from '../../messages/FormatMessage';

type Props = {
  applicationName: string;
};

const HmiPermissionsZApp: React.FC<Props> = ({ applicationName }) => {
  return (
    <>
      <div className={'ds-hmi-permissions-title'}>
        <FormatMessage
          id={'hmi.auth.permissions.title.app.z'}
          values={{ name: applicationName }}
          asHtml
        />
      </div>

      <div className={'ds-hmi-permission-sections'}>
        <ul className={'ds-hmi-z-app-list'}>
          <li>View all data in your PDA</li>
          <li>Manage applications that you have signed into with your PDA</li>
        </ul>
      </div>
    </>
  );
};

export default HmiPermissionsZApp;
