import { AnalyticsEvent } from '../interfaces/analytics-event.interface';

export const AnalyticsClickEvents: { [key: string]: AnalyticsEvent } = {
  learnMoreLink: {
    category: 'HMI Screen',
    action: 'Open Learn More',
    label: 'link',
  },
  policyAndTermsLink: {
    category: '',
    action: 'Open Policies and Terms',
    label: 'link',
  },
  pdaOwnerAgreementLink: {
    category: '',
    action: 'Open PDA Owner Agreement',
    label: 'link',
  },
  dsPrivacyPolicyAgreementLink: {
    category: '',
    action: 'Open DS Privacy Policy agreement',
    label: 'link',
  },
  closeModalScreen: {
    category: '',
    action: 'Close Policies and terms modal',
    label: 'screen_click',
  },
  closeModalBackButton: {
    category: '',
    action: 'Close Policies and terms modal',
    label: 'back_button',
  },
  closeModalXButton: {
    category: '',
    action: 'Close Policies and terms modal',
    label: 'x_button',
  },
  hcfLink: {
    category: '',
    action: 'Open HCF website',
    label: 'link',
  },
  hmiWhatSection: {
    category: 'HMI Screen',
    action: 'Expand What section',
    label: 'button',
  },
  hmiWhySection: {
    category: 'HMI Screen',
    action: 'Expand Why section',
    label: 'button',
  },
  hmiWhenSection: {
    category: 'HMI Screen',
    action: 'Expand When section',
    label: 'button',
  },
  hmiWhereSection: {
    category: 'HMI Screen',
    action: 'Expand Where section',
    label: 'button',
  },
  hmiAgreeButton: {
    category: 'HMI Screen',
    action: 'Agree to proceed',
    label: 'button',
  },
  hmiCancelButton: {
    category: 'HMI Screen',
    action: 'Cancel process',
    label: 'cancel_button',
  },
};
