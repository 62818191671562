import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const RatingBackBottom: React.FC<Props> = ({ children }) => {
  return (
    <div className={'rating-background-bottom'}>
      <div className={'rating-background-bottom-content'}>
        <svg
          width="100"
          height="60.169"
          viewBox="0 0 100 60.169"
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
        >
          <defs>
            <filter
              id="a-2"
              x="0"
              y="0"
              width="100"
              height="60.169"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="2" result="b" />
              <feFlood floodOpacity="0.149" />
              <feComposite operator="in" in2="b" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g className="b" transform="matrix(1, 0, 0, 1, 0, 0)">
            <path
              className="a"
              d="M0,1.507,44-12.5,88,1.507V30.2a5.677,5.677,0,0,1-5.867,5.466H5.867A5.677,5.677,0,0,1,0,30.2Z"
              transform="translate(6 17.5)"
            />
          </g>
        </svg>
      </div>
      {children}
    </div>
  );
};
