import React, { useContext } from 'react';
import HmiContext from '../../context/HmiContext';
import ExpansionPanel from '../ExpansionPanel/ExpansionPanel';
import FormatMessage from '../../messages/FormatMessage';
import AnalyticsContext from '../../context/AnalyticsContext';
import { AnalyticsClickEvents } from '../../utils/AnalyticsEvents';

const HmiEnableToolAnalysis: React.FC = () => {
  const hmiContext = useContext(HmiContext);
  const onClickEvent = useContext(AnalyticsContext)?.onClickEvent;
  const { parentApp, dependencyTools } = hmiContext.hmi;

  if (!parentApp) {
    return null;
  }

  if (parentApp.dependencies && parentApp.dependencies.tools.length > 0) {
    return (
      <>
        <ExpansionPanel
          titleId={'hmi.auth.permissions.analysisWhat'}
          onClick={() => onClickEvent?.(AnalyticsClickEvents.hmiWhatSection)}
        >
          <div>
            <FormatMessage
              id={'hmi.tool.description'}
              values={{ name: parentApp.info.name }}
            />
            <ul className={'instruction-list'}>
              {dependencyTools &&
                dependencyTools.map((tool, index) => {
                  return (
                    <li className={'list-item'} key={tool + index}>
                      {tool}
                    </li>
                  );
                })}
            </ul>
          </div>
        </ExpansionPanel>
        <hr />
      </>
    );
  } else {
    return null;
  }
};

export default HmiEnableToolAnalysis;
