import React, { useContext } from 'react';
import HmiContext from '../../context/HmiContext';
import ExpansionPanel from '../ExpansionPanel/ExpansionPanel';
import AnalyticsContext from '../../context/AnalyticsContext';
import { AnalyticsClickEvents } from '../../utils/AnalyticsEvents';

const HmiPurpose: React.FC = () => {
  const hmiContext = useContext(HmiContext);
  const onClickEvent = useContext(AnalyticsContext)?.onClickEvent;
  const { parentApp, dependencyContracts } = hmiContext.hmi;

  if (!parentApp) {
    return null;
  }

  if (!parentApp.info.dataUsePurpose) {
    return null;
  }

  return (
    <>
      <ExpansionPanel
        titleId={'hmi.auth.permissions.purposeWhy'}
        onClick={() => onClickEvent?.(AnalyticsClickEvents.hmiWhySection)}
      >
        <div>
          {parentApp.info.dataUsePurpose}
          {dependencyContracts && dependencyContracts.length > 0 && (
            <>
              <br />
              {dependencyContracts[0].info.dataUsePurpose}
            </>
          )}
        </div>
      </ExpansionPanel>
      <hr />
    </>
  );
};

export default HmiPurpose;
