import * as React from 'react';
import { AnalyticsEvent } from '../interfaces/analytics-event.interface';

type ContextProps = {
  onClickEvent?: (event: AnalyticsEvent) => void;
};

const AnalyticsContext = React.createContext<ContextProps>({});

export default AnalyticsContext;
