import React from 'react';
import './Hmi.scss';
import HmiPermissions from './HmiPermissions';
import HmiProvider from '../../context/HmiProvider';
import MessagesProvider from '../../context/MessagesProvider';
import { HatApplicationContent } from '../../interfaces/hat-application.interface';
import { AuthApplicationLogo } from '../AuthApplicationLogo/AuthApplicationLogo';

type Props = {
  path?: string;
  applicationId?: string;
  email: string;
  language?: string;
  parentApp: HatApplicationContent;
  dependencyApps?: HatApplicationContent[];
  dependencyContracts?: HatApplicationContent[];
  dependencyTools?: string[] | null;
  onApproved: () => void;
  onRejected: () => void;
};

export const Hmi: React.FC<Props> = props => {
  return (
    <HmiProvider
      parentApp={props.parentApp}
      hatName={props.email}
      language={props.language || 'en'}
      dependencyApps={props.dependencyApps || []}
      dependencyContracts={props.dependencyContracts || []}
      dependencyTools={props.dependencyTools}
      onApproved={props.onApproved}
      onRejected={props.onRejected}
    >
      <MessagesProvider language={props.language}>
        <div className={'ds-hmi'}>
          <AuthApplicationLogo
            src={props.parentApp.info.graphics.logo.normal}
            alt={props.parentApp.info.name}
          />
          <h2 className={'ds-hmi-email'}>{props.email}</h2>
          <HmiPermissions />
        </div>
      </MessagesProvider>
    </HmiProvider>
  );
};
