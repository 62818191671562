import React, { useContext } from 'react';

import './Footer.scss';
import Logo from '../../assets/icons/dataswift-logo-d.svg';
import FormatMessage from '../../messages/FormatMessage';
import MessagesProvider from '../../context/MessagesProvider';
import AnalyticsContext from '../../context/AnalyticsContext';
import { AnalyticsClickEvents } from '../../utils/AnalyticsEvents';

type Props = {
  language?: string;
  wrapperStyles?: object;
};

export const IssuedBy: React.FC<Props> = ({
  language = 'en',
  wrapperStyles,
}) => {
  const onClickEvent = useContext(AnalyticsContext)?.onClickEvent;

  return (
    <MessagesProvider language={language}>
      <div
        className="ds-footer-wrapper"
        style={wrapperStyles ? wrapperStyles : {}}
      >
        <div className={'ds-footer issued-by'}>
          <div className="ds-footer-text">
            <FormatMessage id={'hmi.auth.issuedBy'} />
          </div>

          <img src={Logo} alt="Dataswift Logo" />

          <div className="ds-footer-text">
            <FormatMessage
              id="hmi.dataswift.footer.copyrights"
              values={{ year: new Date().getFullYear() }}
              asHtml
            />
          </div>

          <div
            className="ds-footer-text"
            onClick={() => onClickEvent?.(AnalyticsClickEvents.hcfLink)}
          >
            <FormatMessage id="hmi.dataswift.footer.authorised" asHtml />
          </div>
        </div>
      </div>
    </MessagesProvider>
  );
};
