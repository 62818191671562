import React from 'react';

import Logo from '../../assets/icons/dataswift-logo-d.svg';
import './Footer.scss';
import FormatMessage from '../../messages/FormatMessage';
import MessagesProvider from '../../context/MessagesProvider';

export interface FooterProps {
  language?: string;
  wrapperStyles?: object;
}

const Footer: React.FC<FooterProps> = ({ language = 'en', wrapperStyles }) => {
  return (
    <MessagesProvider language={language}>
      <div
        className="ds-footer-wrapper"
        style={wrapperStyles ? wrapperStyles : {}}
      >
        <div className="ds-footer">
          <img src={Logo} alt="Dataswift Logo" />
          <div className="ds-footer-text">
            <FormatMessage
              id="hmi.dataswift.footer.copyrights"
              values={{ year: new Date().getFullYear() }}
              asHtml
            />
          </div>

          <div className="ds-footer-text">
            <FormatMessage id="hmi.dataswift.footer.authorised" asHtml />
          </div>
        </div>
      </div>
    </MessagesProvider>
  );
};

export default Footer;
