import * as React from 'react';
import { HatApplicationContent } from '../interfaces/hat-application.interface';

type ContextProps = {
  hmi: {
    parentApp: HatApplicationContent | null;
    hatName?: string;
    language: string;
    dependencyApps?: HatApplicationContent[];
    dependencyContracts?: HatApplicationContent[];
    dependencyTools?: string[] | null;
    onApproved: () => void;
    onRejected: () => void;
  };
};

const HmiContext = React.createContext<ContextProps>({
  hmi: {
    hatName: '',
    parentApp: null,
    language: '',
    dependencyApps: [],
    dependencyContracts: [],
    onApproved: () => {},
    onRejected: () => {},
  },
});

export default HmiContext;
