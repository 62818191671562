import React, { useState } from 'react';
import './Input.scss';

type OwnProps = {
  hasError?: boolean;
  errorMessage?: string;
  errorSuggestion?: string;
  passwordMatch?: boolean;
  type: string;
  placeholder?: string;
  hidden?: boolean;
};

type Props = OwnProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

export const Input: React.FC<Props> = ({
  hasError,
  errorMessage,
  errorSuggestion,
  passwordMatch,
  hidden,
  type,
  placeholder,
  ...props
}) => {
  const [passwordMask, setPasswordMask] = useState(true);

  const getType = () => {
    if (type === 'password') {
      return passwordMask ? 'password' : 'text';
    } else if (type === 'email') {
      return 'text';
    }

    return type;
  };

  const getTypeIcon = () => {
    if (type === 'password') {
      if (typeof passwordMatch !== 'undefined') {
        return passwordMatch ? 'check_circle' : 'cancel';
      } else {
        return 'vpn_key';
      }
    } else if (type === 'email') {
      return 'alternate_email';
    }

    return '';
  };

  const getPasswordMatchStyle = () => {
    if (typeof passwordMatch === 'undefined') return {};

    if (passwordMatch) {
      return { color: '#a8c62b', opacity: '1.0' };
    } else {
      return { color: '#F45F09', opacity: '1.0' };
    }
  };

  return (
    <div
      className={'ds-input-wrapper'}
      style={hidden ? { display: 'none' } : { display: 'block' }}
    >
      <div className={'ds-input-container'}>
        <div className={'ds-input-type-icon'}>
          <i className={'material-icons'} style={getPasswordMatchStyle()}>
            {getTypeIcon()}
          </i>
        </div>
        <div className={'ds-input-back'}>
          <input
            {...props}
            required
            className={`ds-input ${hasError ? 'ds-input-error' : ''}`}
            type={getType()}
          />
          <label htmlFor={props.id} className={'placeholder'}>
            <span>{placeholder}</span>
          </label>
          {type === 'password' && (
            <button
              className={'ds-input-password-toggle'}
              onClick={() => setPasswordMask(!passwordMask)}
            >
              <i className={'material-icons'}>
                {passwordMask ? 'visibility_off' : 'visibility'}
              </i>
            </button>
          )}
        </div>
      </div>

      {hasError && errorMessage && (
        <div className={'ds-input-error-message'}>{errorMessage}</div>
      )}

      {hasError && errorSuggestion && (
        <div className={'ds-input-error-suggestion'}>{errorSuggestion}</div>
      )}
    </div>
  );
};
