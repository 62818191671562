import React, { useState, useEffect } from 'react';
import './AuthApplicationLogo.scss';
// @ts-ignore
import logo from '../../assets/icons/dataswift-logo-d.svg';

type Props = {
  src?: string | null;
  alt?: string | null;
  state?: string | null;
};

export const AuthApplicationLogo: React.FC<Props> = ({ src, state }) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    setImageSrc(src || logo);
  }, [src]);

  return (
    <div className={'ds-auth-logo-wrapper'}>
      {state !== 'pending' && (
        <img src={imageSrc} alt={''} onError={() => setImageSrc(logo)} />
      )}
    </div>
  );
};
