import FormatMessage from '../../messages/FormatMessage';
import HmiAccessingAndProcessing from './HmiAccessingAndProcessing';
import HmiPurpose from './HmiPurpose';
import HmiDuration from './HmiDuration';
import HmiEnableToolAnalysis from './HmiEnableToolAnalysis';
import HmiConnection from './HmiConnection';
import React, { useContext } from 'react';
import HmiContext from '../../context/HmiContext';
import { CertificateStatus } from '../CertificateStatus/CertificateStatus';

const HmiPermissionsDefaultApp: React.FC = () => {
  const hmiContext = useContext(HmiContext);
  const { parentApp } = hmiContext.hmi;

  if (!parentApp) return null;

  return (
    <>
      <div className={'ds-hmi-permissions-certificate'}>
        <CertificateStatus
          score={parentApp.info.rating.score}
          points={parentApp.info.rating.points || 0}
          learnMoreSection
        />
      </div>

      <div className={'ds-hmi-permissions-title'}>
        <FormatMessage
          id={'hmi.auth.permissions.title.app.default'}
          values={{ name: parentApp.developer.name }}
        />
        {parentApp.info.name}:
      </div>

      <div className={'ds-hmi-permission-sections'}>
        <HmiAccessingAndProcessing />
        <HmiPurpose />
        <HmiDuration />
        <HmiEnableToolAnalysis />
        <HmiConnection />
      </div>
    </>
  );
};

export default HmiPermissionsDefaultApp;
