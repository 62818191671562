import React, { useContext } from 'react';
import './CertificateStatus.scss';
import { ProgressRing } from './ProgressRing';
import { RatingBackground } from './RatingBackground';
import { RatingBackBottom } from './RatingBackBottom';
import FormatMessage from '../../messages/FormatMessage';
import { hmiConfig } from '../../hmi.config';
import AnalyticsContext from '../../context/AnalyticsContext';
import { AnalyticsClickEvents } from '../../utils/AnalyticsEvents';

type Props = {
  score: string;
  points: number;
  text?: string;
  learnMoreSection?: boolean;
};

export const CertificateStatus: React.FC<Props> = ({
  score,
  points,
  text,
  learnMoreSection,
}) => {
  const onClickEvent = useContext(AnalyticsContext)?.onClickEvent;

  return (
    <div className={'certificate-status'}>
      <div className={'certificate-status-progress-wrapper'}>
        <ProgressRing points={points} />
      </div>
      <div className={'certificate-status-rating-bottom-wrapper'}>
        <RatingBackBottom>
          <RatingBackground points={points}>
            <div className={'certificate-status-rating'}>{score}</div>
          </RatingBackground>

          <div className={'certificate-status-assured'}>
            {text ? text : <FormatMessage id={'hmi.rating.assured'} />}
          </div>
        </RatingBackBottom>
      </div>
      {learnMoreSection && (
        <a
          href={hmiConfig.links.hatdexRating}
          className={'certificate-status-learn-more'}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => onClickEvent?.(AnalyticsClickEvents.learnMoreLink)}
        >
          <FormatMessage id={'hmi.rating.learnMore'} />
        </a>
      )}
    </div>
  );
};
