import React, { useEffect, useRef } from 'react';
import './Modal.scss';
import closeIcon from '../../assets/icons/button_close.svg';
import FormatMessage from '../../messages/FormatMessage';
import { AnalyticsClickEvents } from '../../utils/AnalyticsEvents';
import { AnalyticsEvent } from '../../interfaces/analytics-event.interface';

type Props = {
  open: boolean;
  onClose: (event: AnalyticsEvent) => void;
  titleMessageId: string;
  children: React.ReactNode;
};

const Modal: React.FC<Props> = ({
  open,
  onClose,
  titleMessageId,
  children,
}) => {
  const content = useRef<HTMLObjectElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (content.current === e.target) {
        onClose(AnalyticsClickEvents.closeModalScreen);
      }
    };

    document.addEventListener('mousedown', handleClick, false);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, [onClose]);

  return (
    <div
      id="DsModal"
      ref={content}
      style={open ? { display: 'block' } : {}}
      className="ds-modal"
    >
      <div className="ds-modal-content">
        <div className="ds-modal-header">
          <div className={'ds-modal-text-title'}>
            <FormatMessage id={titleMessageId} />
          </div>
          <button
            onClick={() => onClose(AnalyticsClickEvents.closeModalXButton)}
            className={'ds-modal-close-button'}
          >
            <img src={closeIcon} alt={'Close modal'} />
          </button>
        </div>
        <div className="ds-modal-body ds-modal-text-content">{children}</div>
        <div className="ds-modal-footer">
          <button
            onClick={() => onClose(AnalyticsClickEvents.closeModalBackButton)}
            className={'ds-modal-btn-accent'}
          >
            <span>
              <FormatMessage id={'hmi.auth.modal.backBtn'} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
