import React, { Component } from 'react';
import { HatApplicationContent } from '../interfaces/hat-application.interface';
import HmiContext from './HmiContext';

type Props = {
  hatName?: string;
  parentApp: HatApplicationContent;
  dependencyApps?: HatApplicationContent[];
  dependencyContracts?: HatApplicationContent[];
  dependencyTools?: string[] | null;
  language: string;
  onApproved: () => void;
  onRejected: () => void;
};
export default class HmiProvider extends Component<Props> {
  state = {
    hmi: {
      hatName: this.props.hatName,
      parentApp: this.props.parentApp,
      dependencyApps: this.props.dependencyApps,
      dependencyContracts: this.props.dependencyContracts,
      language: this.props.language,
      onApproved: this.props.onApproved,
      onRejected: this.props.onRejected,
      dependencyTools: this.props.dependencyTools,
    },
  };

  render() {
    return (
      <HmiContext.Provider
        value={{
          hmi: this.state.hmi,
        }}
      >
        {this.props.children}
      </HmiContext.Provider>
    );
  }
}
