import React, { useContext, useMemo } from 'react';
import HmiContext from '../../context/HmiContext';
import ExpansionPanel from '../ExpansionPanel/ExpansionPanel';
import FormatMessage from '../../messages/FormatMessage';
import AnalyticsContext from '../../context/AnalyticsContext';
import { AnalyticsClickEvents } from '../../utils/AnalyticsEvents';

const HmiAccessingAndProcessing: React.FC = () => {
  const hmiContext = useContext(HmiContext);
  const onClickEvent = useContext(AnalyticsContext)?.onClickEvent;

  const { parentApp, dependencyContracts } = hmiContext.hmi;

  const message = useMemo(() => {
    if (!parentApp) {
      return '';
    }
    const containsWrite = parentApp.permissions.rolesGranted.find(
      role => role.role === 'namespacewrite'
    );
    const containsRead = parentApp.permissions.rolesGranted.find(
      role => role.role === 'namespaceread'
    );

    if (!!containsWrite && !!containsRead) {
      return (
        <FormatMessage
          id={'hmi.auth.permissions.accessingReadWrite'}
          values={{
            appName: parentApp.info.name,
            orgName: parentApp.developer.name,
          }}
        />
      );
    } else if (!!containsWrite && !containsRead) {
      return (
        <FormatMessage
          id={'hmi.auth.permissions.accessingWriteOnly'}
          values={{
            appName: parentApp.info.name,
            orgName: parentApp.developer.name,
          }}
        />
      );
    } else if (!!containsRead && !containsWrite) {
      // TODO Resolve this
      return (
        <FormatMessage
          id={'hmi.auth.permissions.accessingReadOnly'}
          values={{
            appName: parentApp.info.name,
            orgName: parentApp.developer.name,
            detail: containsRead.detail,
          }}
        />
      );
    } else {
      return '';
    }
  }, [parentApp]);

  const contractedAppMessage = useMemo(() => {
    if (!dependencyContracts || dependencyContracts.length === 0) {
      return '';
    }

    const contract = dependencyContracts[0];

    return (
      <FormatMessage
        id={'hmi.auth.permissions.contractedApp'}
        values={{
          appName: contract.info.name,
          orgName: contract.developer.name,
        }}
      />
    );
  }, [dependencyContracts]);

  if (!parentApp || !message) {
    return null;
  }

  return (
    <>
      <hr />
      <ExpansionPanel
        titleId={'hmi.auth.permissions.whatAccessing'}
        onClick={() => onClickEvent?.(AnalyticsClickEvents.hmiWhatSection)}
      >
        {message && <div>{message}</div>}
        {contractedAppMessage && <div>{contractedAppMessage}</div>}
      </ExpansionPanel>
      <hr />
    </>
  );
};

export default HmiAccessingAndProcessing;
